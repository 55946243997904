import styles from '../styles/Footer.module.scss'

export default function Footer() {
  return(
    <div className={styles.footer}>
      <div className={styles.content}>
        <h6>27 Old Gloucester Street,LONDON,WC1N 3AX,UNITED KINGDOM</h6>
        <h6>
          © 2023 DIAMOND TELECOM LLP All rights reserved.
        </h6>
      </div>
    </div>
  )
}